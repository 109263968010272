<script>
export default {
  name: "IndicatorDisplay2",
  props: {
    indicator: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      animatedValue: 0,
      descriptionVisible: false,
    };
  },
  computed: {
    isPercentage() {
      return this.indicator.type === "perc";
    },
    isText() {
      return this.indicator.type === "text";
    },
    displayValue() {
      if (this.isText) {
        return this.indicator.value;
      } else if (this.isPercentage) {
        return `${this.animatedValue.toFixed(this.indicator.decimals)}%`;
      }
      return `${this.animatedValue.toFixed(this.indicator.decimals)} ${this
        .indicator.unit || ""}`;
    },
    progressValue() {
      if (this.isPercentage) {
        return (this.animatedValue / 100) * this.indicator.tot;
      }
      return (this.indicator.value / this.indicator.tot) * 100;
    },
    progressColor() {
      const value = this.isPercentage
        ? this.animatedValue
        : (this.indicator.value / this.indicator.tot) * 100;
      if (value < 50) {
        return "#f44336"; // red
      } else if (value < 75) {
        return "#ffc107"; // yellow
      } else {
        return "#4caf50"; // green
      }
    },
    strokeDasharray() {
      const percentage = this.isPercentage
        ? (this.animatedValue / 100) * 100
        : (this.indicator.value / this.indicator.tot) * 100;
      return `${percentage} ${100 - percentage}`;
    },
  },
  mounted() {
    if (!this.isText) {
      if (this.isPercentage) {
        this.animateValue(
          0,
          (this.indicator.value / this.indicator.tot) * 100,
          1000
        );
      } else {
        this.animateValue(0, this.indicator.value, 1000);
      }
    }
  },
  methods: {
    handleHoverQuestionMark() {
      this.descriptionVisible = true;
      setTimeout(() => {
        this.descriptionVisible = false;
      }, 3000);
    },
    animateValue(start, end, duration) {
      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        this.animatedValue = progress * (end - start) + start;
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    },
  },
};
</script>

<template>
  <div
    :class="
      isPercentage ? 'indicator-display-circle' : 'indicator-display-square'
    "
  >
    <div class="question-mark">
      <b-icon icon="info-circle" @click="handleHoverQuestionMark"></b-icon>
    </div>
    <div v-if="descriptionVisible" class="description-container">
      {{ indicator.description }}
    </div>
    <div v-if="isPercentage" class="circle">
      <div class="circle-inner">
        <svg viewBox="0 0 36 36" class="circular-chart">
          <path
            class="circle-bg"
            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path
            class="circle-progress"
            :stroke-dasharray="strokeDasharray"
            :stroke="progressColor"
            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          />
        </svg>
        <div class="circle-content">
          <h3>{{ indicator.title }}</h3>
          <p>{{ displayValue }}</p>
        </div>
      </div>
    </div>
    <div v-else-if="isText" class="text-card">
      <div class="text-content">
        <h3>{{ indicator.title }}</h3>
        <p style="font-size: 3rem;">{{ displayValue }}</p>
      </div>
    </div>
    <div v-else class="card">
      <div class="card-content">
        <h3>{{ indicator.title }}</h3>
        <p>{{ displayValue }}</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.description-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px 15px;
  background-color: rgba(0, 0, 0, 0.666);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.2rem;
}
.question-mark {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
.indicator-display-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 170px;
}

.indicator-display-square {
  width: 170px;
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  position: relative;
  width: 160px;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
}

.text-card,
.card {
  border: none;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: white;
}

.card {
  border-radius: 5px;
}

.circle-inner {
  position: relative;
  width: 160px;
  height: 160px;
}

.circular-chart {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 250px;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 4.5;
}

.circle-progress {
  fill: none;
  stroke-width: 4.5;
  stroke-linecap: round;
  transition: stroke-dasharray 0.6s ease 0s;
}

.circle-content,
.card-content,
.text-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.circle-content h3,
.card-content h3,
.text-content h3 {
  font-size: 14px;
  margin: 0;
  color: #4caf50;
}

.circle-content p,
.card-content p,
.text-content p {
  font-size: 18px;
  margin: 5px 0 0;
  font-weight: bold;
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
