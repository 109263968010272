<template>
  <div class="chart-container">
    <canvas ref="lineChart"></canvas>
  </div>
</template>

<script>
import { Chart } from "chart.js";

export default {
  name: "StackedLineChart",
  props: {
    labels: {
      type: Array,
      required: true,
    },
    datasets: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.renderChart();
  },
  watch: {
    labels: "renderChart",
    datasets: "renderChart",
  },
  methods: {
    renderChart() {
      if (this.$data._chart) {
        this.$data._chart.destroy();
      }

      this.$data._chart = new Chart(this.$refs.lineChart, {
        type: "line", // Type set to "line" for stacked line chart
        data: {
          labels: this.labels,
          datasets: this.datasets.map((dataset, index) => ({
            label: dataset.label,
            data: dataset.data,
            backgroundColor: this.getBackgroundColor(index),
            borderColor: this.getBorderColor(index),
            borderWidth: 2,
            fill: true, // Enable fill to create stacked effect
            tension: 0.3, // Adjust tension for smooth curves
          })),
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: "X Axis Title",
              },
            },
            y: {
              display: true,
              title: {
                display: true,
                text: "Y Axis Title",
              },
              stacked: true, // Enable stacking for y-axis
            },
          },
        },
      });
    },
    getBackgroundColor(index) {
      const colors = ["rgba(166, 184, 201, 0.5)", "rgba(128, 255, 128, 0.5)"];
      return colors[index];
    },
    getBorderColor(index) {
      const colors = ["rgba(166, 184, 201, 1)", "rgba(128, 255, 128, 1)"];
      return colors[index];
    },
  },
};
</script>

<style scoped>
.chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
</style>
