<template>
  <div class="chart-container">
    <canvas ref="lineChart"></canvas>
  </div>
</template>

<script>
import { Chart } from "chart.js";

export default {
  name: "LineGraph",
  props: {
    datasets: {
      type: Array,
      required: true,
    },
    labels: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.renderChart();
  },
  watch: {
    datasets: "renderChart",
    labels: "renderChart",
  },
  methods: {
    renderChart() {
      if (this.$data._chart) {
        this.$data._chart.destroy();
      }

      this.$data._chart = new Chart(this.$refs.lineChart, {
        type: "line",
        data: {
          labels: this.labels,
          datasets: this.datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: "X Axis Title",
              },
            },
            y: {
              display: true,
              title: {
                display: true,
                text: "Y Axis Title",
              },
            },
          },
        },
      });
    },
  },
};
</script>

<style scoped>
.chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
</style>
