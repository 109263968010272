<template>
  <div class="chart-container">
    <canvas ref="bubbleChart"></canvas>
  </div>
</template>

<script>
import { Chart } from "chart.js";

export default {
  name: "BubbleGraph",
  props: {
    datasets: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.renderChart();
  },
  watch: {
    datasets: "renderChart",
  },
  methods: {
    renderChart() {
      if (this.$data._chart) {
        this.$data._chart.destroy();
      }

      this.$data._chart = new Chart(this.$refs.bubbleChart, {
        type: "bubble", // Type changed to "bubble" for bubble chart
        data: {
          datasets: this.datasets.map((dataset, index) => ({
            label: dataset.label,
            data: dataset.data,
            backgroundColor: this.getBackgroundColor(index),
            borderColor: this.getBorderColor(index),
            borderWidth: 1,
          })),
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: "X Axis Title",
              },
            },
            y: {
              display: true,
              title: {
                display: true,
                text: "Y Axis Title",
              },
            },
          },
        },
      });
    },
    getBackgroundColor(index) {
      const colors = [
        "rgba(255, 99, 132, 0.5)",
        "rgba(54, 162, 235, 0.5)",
        "rgba(255, 206, 86, 0.5)",
        "rgba(75, 192, 192, 0.5)",
        "rgba(153, 102, 255, 0.5)",
        "rgba(255, 159, 64, 0.5)",
      ];
      return colors[index % colors.length];
    },
    getBorderColor(index) {
      const colors = [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ];
      return colors[index % colors.length];
    },
  },
};
</script>

<style scoped>
.chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
</style>
